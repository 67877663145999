export default {
  'No results found for {inputQuery}': 'Geen resultaten gevonden voor {inputQuery}',
  'More results for {inputQuery}': 'Meer resultaten voor {inputQuery}',
  'Show more': 'Meer opties',
  'Show less': 'Minder opties',
  '{count} results': 'Geen resultaten | 1 resultaat | {count} resultaten',
  'Show {totalItems} results': 'Geen resultaten | Toon 1 resultaat | Toon {totalItems} resultaten',
  "You have viewed {viewed} of 1 {total}": "Je hebt gekeken naar {viewed} van {total} artikelen",

  //PDP
  "Product has been added to your shopping cart": "Product is toegevoegd aan je winkelwagen",
  "Added to your shopping cart": "Toegevoegd aan jouw winkelwagen",
  "In stock": "Op voorraad",
  "Order now!": "Bestel nu!",

  //Cart
  "Shopping cart": "Winkelwagen",
  "To shopping cart": "Naar winkelwagen",
  "To checkout": "Ik wil bestellen",
  "Secure connection": "Bij ons betaal je veilig",
  "Continue shopping": "Verder winkelen",
  "You have no product(s) in your shopping cart.": "Je hebt geen product(en) in je winkelwagen.",
  "Click {url} to continue shopping": "Klik {url} om verder te gaan met winkelen.",
  "here": "hier",
  "Loading shopping cart items...": "Winkelmandje aan het laden...",
  "Description": "Omschrijving",
  "Quantity": "Aantal",
  "Unit price": "Stukprijs",
  "Subtotal": "Subtotaal",
  'Grand Total': 'Totaal',
  "SKU": "Artikelnummer",

  //Checkout
  "Address": "Gegevens",
  "Billing address": "Factuurgegevens",
  "Shipping address": "Adresgegevens",
  "Shipping": "Bezorging",
  "Payment": "Betaling",
  "Back to cart": "Terug naar winkelwagen",
  "To shipping": "Naar bezorging",
  "To payment": "Ik wil betalen",
  "Complete order": "Ik wil afrekenen",
  "Name": "Voornaam",
  "Last name": "Achternaam",
  "Company": "Bedrijf",
  "Tax number": "BTW nummer",
  "Country": "Land",
  "-- Choose your country --": "-- Kies je land --",
  "Street": "Straat",
  "House number": "Huisnummer",
  "House no.": "Huisnr",
  "Addition": "Toevoeging",
  "Add.": "Toev.",
  "Postal code": "Postcode",
  "City": "Plaats",
  "Telephone": "Telefoon",
  "Billing address is different from the address above": "Factuuradres is anders dan bovenstaand adres",
  "Email address": "E-mail adres",
  "Password": "Wachtwoord",
  "You have an account. Would you like to {action}?": "Je hebt al een account. Wil je {action}?",
  "Already have an account with us? Log in {here} and we will retrieve your details.": "Heb je al een account? Log {here} in en wij halen je gegevens op.",
  "You don't have an account. Would you like to {action}?": "Je hebt nog geen account. Wil je {action}?",
  "login": "inloggen",
  "register":"registreren",
  "Log in": "Inloggen",
  "Forgot password?": "Wachtwoord vergeten?",
  "Add new address": "Nieuw adres toevoegen",
  "Select your address": "Selecteer je gewenste adres",
  "The most used payment method in The Netherlands": "De meest gebruikte betaalmethode in Nederland",
  "Without extra fees": "Zonder extra kosten",
  "Payment methods": "Betaalmethode",
  "Payment method": "Betaalmethode",
  "Shipping methods": "Bezorgmethodes",
  "Shipping method": "Bezorgmethode",
  "Overview of your order": "Overzicht van je bestelling",
  "Items": "Artikelen",
  "Cart totals": "Totaal overzicht",
  "Free": "Gratis",
  "Shipping & Handling": "Verzending & Verwerking",
  "Your order will be delivered on": "Je bestelling wordt bezorgd op",
  "Change delivery address": "Wijzig bezorgadres",
  "I have a discount code": "Ik heb een kortingscode",
  "Apply": "Toepassen",
  "Cancel": "Annuleren",
  "Your applied coupon code is {couponCodeInput}.": "Je toegepaste kortingscode is {couponCodeInput}.",
  "Choose your store": "Kies je afhaallocatie",
  "Store": "Winkel",
  "You can pick up your order at": "Je kunt je bestelling afhalen bij",
  "Click here to automatically retrieve your address": "Klik hier om je adres automatisch op te vragen",
  "We have found the following address": "We hebben het volgende adres gevonden",
  "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het juist is ingevuld?",
  "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
  "Choose your bank": "Kies je bank",
  "Choose your creditcard": "Kies je creditcard",
  "The password needs at least %1 characters. Create a new password and try again.": "Het wachtwoord heeft minstens 7 tekens nodig. Maak een nieuw wachtwoord en probeer het opnieuw.",
  "Newsletter":"Nieuwsbrief",
  "Subscribe to newsletter":"Inschrijven voor nieuwsbrief",
  'Enter a correct value': 'Voer a.u.b. een geldige waarde in',
  'Please wait, loading payment methods': 'Een ogenblik geduld, we halen de betaalmethodes op',
  'Please wait, loading shipping methods': 'Een ogenblik geduld, we halen de bezorgmethodes op',
  'Select payment method': 'Ik wil betalen',
  'Start payment': 'Ik wil afrekenen',
  'Firstname': 'Voornaam',
  'Lastname': 'Achternaam',
  'Choose an adresss': 'Kies een adres',
  'incl. VAT': 'BTW inbegrepen',
  'Change': 'Wijzig',
  'Your details': 'Je gegevens',
  'Discount': 'Korting',
  'Coupon code': 'Kortingscode',
  'The coupon code isn\'t valid. Verify the code and try again.': 'De kortingscode is niet geldig. Verifieer de kortingscode en probeer het nog een keer.',


  //Wishlist
  "My wishlist": "Mijn favorieten",
  "There are no products in your wishlist": "Je hebt nog geen favorieten toegevoegd.",

  //Cart
  "One or more products are out of stock.": "Een of meerdere producten is/zijn momenteel niet leverbaar."
}
